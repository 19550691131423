<template>
  <div class="dashboard workout-pt-schedule-page">
    <div class="workout-pt-schedule-top">
      <tabs/>
    </div>
    <div class="workout-pt-schedule-content">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-5">
            <button type="button" class="workout-pt-schedule-calendar-btn" @click="toggleCalendar">
              <p class="workout-pt-schedule-calendar-btn-title">Dia</p>
              <p class="workout-pt-schedule-calendar-btn-date">{{ calendarDate }}</p>
            </button>
            <div class="workout-pt-schedule-calendar">
              <v-date-picker :attributes="calendarAttributes" v-model="calendarDate" :model-config="modelConfig" :min-date='new Date()'></v-date-picker>
            </div>
          </div>
          <div class="col-12 col-lg-7">
            <div class="workout-pt-schedule-wrapper">
              <div class="author-item">
                <div class="author-item-heading d-flex justify-content-between align-items-center">
                  <div class="d-flex justify-content-start align-items-center">
                    <div class="author-image">
                      <img src="https://fakeimg.pl/40/" alt="">
                    </div>
                    <p class="section-title">Johanna Cunningham</p>
                  </div>
                  <div class="d-flex justify-content-end align-items-center">
                    <router-link to="/workout-pt-detail" class="heading-link">Ver perfil</router-link>
                  </div>
                </div>
                <Slick ref="slick" :options="slickOptions" class="workout-pt-author-slider">
                  <div class="workout-pt-author-slider-item">
                    <div class="workout-hours-item">
                      <label>
                        <input type="radio" name="author-slider-item-radio">
                        <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                          <span>14:00</span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="workout-pt-author-slider-item">
                    <div class="workout-hours-item">
                      <label>
                        <input type="radio" name="author-slider-item-radio">
                        <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                          <span>14:00</span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="workout-pt-author-slider-item">
                    <div class="workout-hours-item">
                      <label>
                        <input type="radio" name="author-slider-item-radio">
                        <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                          <span>14:00</span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="workout-pt-author-slider-item">
                    <div class="workout-hours-item">
                      <label>
                        <input type="radio" name="author-slider-item-radio">
                        <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                          <span>14:00</span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="workout-pt-author-slider-item">
                    <div class="workout-hours-item">
                      <label>
                        <input type="radio" name="author-slider-item-radio">
                        <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                          <span>14:00</span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="workout-pt-author-slider-item">
                    <div class="workout-hours-item">
                      <label>
                        <input type="radio" name="author-slider-item-radio">
                        <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                          <span>14:00</span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="workout-pt-author-slider-item">
                    <div class="workout-hours-item">
                      <label>
                        <input type="radio" name="author-slider-item-radio">
                        <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                          <span>14:00</span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="workout-pt-author-slider-item">
                    <div class="workout-hours-item">
                      <label>
                        <input type="radio" name="author-slider-item-radio">
                        <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                          <span>14:00</span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="workout-pt-author-slider-item">
                    <div class="workout-hours-item">
                      <label>
                        <input type="radio" name="author-slider-item-radio">
                        <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                          <span>14:00</span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="workout-pt-author-slider-item">
                    <div class="workout-hours-item">
                      <label>
                        <input type="radio" name="author-slider-item-radio">
                        <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                          <span>14:00</span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="workout-pt-author-slider-item">
                    <div class="workout-hours-item">
                      <label>
                        <input type="radio" name="author-slider-item-radio">
                        <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                          <span>14:00</span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="workout-pt-author-slider-item">
                    <div class="workout-hours-item">
                      <label>
                        <input type="radio" name="author-slider-item-radio">
                        <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                          <span>14:00</span>
                        </div>
                      </label>
                    </div>
                  </div>
                </Slick>
              </div>
              <div class="author-item">
                <div class="author-item-heading d-flex justify-content-between align-items-center">
                  <div class="d-flex justify-content-start align-items-center">
                    <div class="author-image">
                      <img src="https://fakeimg.pl/40/" alt="">
                    </div>
                    <p class="section-title">Johanna Cunningham</p>
                  </div>
                  <div class="d-flex justify-content-end align-items-center">
                    <router-link to="/workout-pt-detail" class="heading-link">Ver perfil</router-link>
                  </div>
                </div>
                <Slick ref="slick" :options="slickOptions" class="workout-pt-author-slider">
                  <div class="workout-pt-author-slider-item">
                    <div class="workout-hours-item">
                      <label>
                        <input type="radio" name="author-slider-item-radio">
                        <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                          <span>14:00</span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="workout-pt-author-slider-item">
                    <div class="workout-hours-item">
                      <label>
                        <input type="radio" name="author-slider-item-radio">
                        <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                          <span>14:00</span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="workout-pt-author-slider-item">
                    <div class="workout-hours-item">
                      <label>
                        <input type="radio" name="author-slider-item-radio">
                        <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                          <span>14:00</span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="workout-pt-author-slider-item">
                    <div class="workout-hours-item">
                      <label>
                        <input type="radio" name="author-slider-item-radio">
                        <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                          <span>14:00</span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="workout-pt-author-slider-item">
                    <div class="workout-hours-item">
                      <label>
                        <input type="radio" name="author-slider-item-radio">
                        <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                          <span>14:00</span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="workout-pt-author-slider-item">
                    <div class="workout-hours-item">
                      <label>
                        <input type="radio" name="author-slider-item-radio">
                        <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                          <span>14:00</span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="workout-pt-author-slider-item">
                    <div class="workout-hours-item">
                      <label>
                        <input type="radio" name="author-slider-item-radio">
                        <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                          <span>14:00</span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="workout-pt-author-slider-item">
                    <div class="workout-hours-item">
                      <label>
                        <input type="radio" name="author-slider-item-radio">
                        <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                          <span>14:00</span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="workout-pt-author-slider-item">
                    <div class="workout-hours-item">
                      <label>
                        <input type="radio" name="author-slider-item-radio">
                        <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                          <span>14:00</span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="workout-pt-author-slider-item">
                    <div class="workout-hours-item">
                      <label>
                        <input type="radio" name="author-slider-item-radio">
                        <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                          <span>14:00</span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="workout-pt-author-slider-item">
                    <div class="workout-hours-item">
                      <label>
                        <input type="radio" name="author-slider-item-radio">
                        <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                          <span>14:00</span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="workout-pt-author-slider-item">
                    <div class="workout-hours-item">
                      <label>
                        <input type="radio" name="author-slider-item-radio">
                        <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                          <span>14:00</span>
                        </div>
                      </label>
                    </div>
                  </div>
                </Slick>
              </div>
              <div class="author-item">
                <div class="author-item-heading d-flex justify-content-between align-items-center">
                  <div class="d-flex justify-content-start align-items-center">
                    <div class="author-image">
                      <img src="https://fakeimg.pl/40/" alt="">
                    </div>
                    <p class="section-title">Johanna Cunningham</p>
                  </div>
                  <div class="d-flex justify-content-end align-items-center">
                    <router-link to="/workout-pt-detail" class="heading-link">Ver perfil</router-link>
                  </div>
                </div>
                <Slick ref="slick" :options="slickOptions" class="workout-pt-author-slider">
                  <div class="workout-pt-author-slider-item">
                    <div class="workout-hours-item">
                      <label>
                        <input type="radio" name="author-slider-item-radio">
                        <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                          <span>14:00</span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="workout-pt-author-slider-item">
                    <div class="workout-hours-item">
                      <label>
                        <input type="radio" name="author-slider-item-radio">
                        <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                          <span>14:00</span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="workout-pt-author-slider-item">
                    <div class="workout-hours-item">
                      <label>
                        <input type="radio" name="author-slider-item-radio">
                        <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                          <span>14:00</span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="workout-pt-author-slider-item">
                    <div class="workout-hours-item">
                      <label>
                        <input type="radio" name="author-slider-item-radio">
                        <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                          <span>14:00</span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="workout-pt-author-slider-item">
                    <div class="workout-hours-item">
                      <label>
                        <input type="radio" name="author-slider-item-radio">
                        <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                          <span>14:00</span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="workout-pt-author-slider-item">
                    <div class="workout-hours-item">
                      <label>
                        <input type="radio" name="author-slider-item-radio">
                        <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                          <span>14:00</span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="workout-pt-author-slider-item">
                    <div class="workout-hours-item">
                      <label>
                        <input type="radio" name="author-slider-item-radio">
                        <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                          <span>14:00</span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="workout-pt-author-slider-item">
                    <div class="workout-hours-item">
                      <label>
                        <input type="radio" name="author-slider-item-radio">
                        <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                          <span>14:00</span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="workout-pt-author-slider-item">
                    <div class="workout-hours-item">
                      <label>
                        <input type="radio" name="author-slider-item-radio">
                        <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                          <span>14:00</span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="workout-pt-author-slider-item">
                    <div class="workout-hours-item">
                      <label>
                        <input type="radio" name="author-slider-item-radio">
                        <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                          <span>14:00</span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="workout-pt-author-slider-item">
                    <div class="workout-hours-item">
                      <label>
                        <input type="radio" name="author-slider-item-radio">
                        <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                          <span>14:00</span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="workout-pt-author-slider-item">
                    <div class="workout-hours-item">
                      <label>
                        <input type="radio" name="author-slider-item-radio">
                        <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                          <span>14:00</span>
                        </div>
                      </label>
                    </div>
                  </div>
                </Slick>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Components
import Slick from 'vue-slick';
import tabs from "@/components/workouts/workout-pt-tabs.vue";

let months = new Array('Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro');
let today = new Date();
let day = String(today.getDate()).padStart(2, '0');
let month = String(today.getMonth() + 1).padStart(2, '0');
let year = today.getFullYear();
let btnMonth = months[today.getMonth()];
let btnTodayDate = day + " " + btnMonth + " " + year;
let calendarTodayDate = day + "/" + month + "/" + year;

export default {
  name: "workout-pt-schedule",
  metaInfo () {
    return {
      title: this.$root.translations.treinar_pt,
    }
  },
  components : {
    Slick,
    tabs,
  },
  data: function () {
    return {
      calendarDate: calendarTodayDate,
      btnDate: btnTodayDate,
      modelConfig: {
        type: 'string',
        //mask: 'DD MMMM YYYY',
        mask: 'DD/MM/YYYY',
      },
      slickOptions: {
        slidesToScroll: 1,
        pauseOnHover: true,
        autoplay: false,
        arrows: false,
        dots: false,
        infinite: true,
        swipeToSlide: true,
        touchMove: true,
        speed: 1000,
        variableWidth: true
      },
      calendarAttributes: [
        {
          dot: {
            style: {
              backgroundColor: '#a01e1d',
            }
          },
          dates: [
            new Date(2021, 0, 4), // Jan 4th
            new Date(2021, 0, 10), // Jan 10th
            new Date(2021, 0, 15), // Jan 15th
          ],
        },
        {
          dot: {
            style: {
              backgroundColor: '#777777',
            }
          },
          dates: [
            new Date(2021, 0, 4), // Jan 4th
            new Date(2021, 0, 10), // Jan 10th
            new Date(2021, 0, 15), // Jan 15th
          ],
        },
      ],
    }
  },
  methods: {
    toggleCalendar() {
      let element = document.getElementsByClassName("workout-pt-schedule-calendar")[0];

      if ( element.classList.contains("open") ) {
        element.classList.remove("open");
      } else {
        element.classList.add("open");
      }
    }
  },
  created() {
    this.$root.pageTitle = this.$root.translations.treino_pt;
  }
}
</script>