<template>
  <div class="box-page-menu">
    <div class="container">
      <div class="box-tabs-wrapper d-xl-flex justify-content-between align-items-end">
        <div class="box-tabs d-flex justify-content-start">
          <router-link to="/workout-pt-schedule" class="box-tab-item">Agendar</router-link>
          <router-link to="/workout-pt-appointment" href="" class="box-tab-item">Marcações</router-link>
          <!--router-link to="/" class="box-tab-item">Historico</router-link-->
        </div>
        <div class="box-page-menu-right d-xl-flex justify-content-end align-items-center" v-if="this.$root.showCredits">
          <div class="credits-wrapper d-flex justify-content-between align-items-center justify-content-xl-end">
            <p class="credits-title"><span>0</span> Créditos Disponiveis</p>
            <button type="button" class="credits-btn">
              <plus/>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import plus from "@/components/svg/plus.vue";

export default {
  name: "tabs",
  components: {
    plus
  },
}
</script>